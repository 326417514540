.skeleton-box .skeleton-empty {
  display: block;
  background: #e9f2f5;
  min-height: 1rem;
  position: relative;
  overflow: hidden;
}

.skeleton-box .skeleton-empty::after {
  border: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    transparent 0,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    transparent
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
}

tr.active,
tr.active a {
  color: #1cc54a !important;
}

.sticky-bottom-toast {
  position: fixed;
  top: 8%;
  left: 90%;
  transform: translateX(-50%);
  z-index: 1000;
}

.modal-open {
  overflow: unset !important;
}
